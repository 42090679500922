import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorPageComponent } from '@core/components/error-page/error-page.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SharedUIModule } from '@shared/shared-ui.module';

/**
 * Internal Server Error (500 HTTP Status):
 *
 * A 500 Internal Server Error is a generic HTTP status code indicating that something has gone
 * wrong on the web server, but the server cannot be more specific about the exact problem. It's a
 * catch-all error message that indicates an unexpected condition prevented the server from
 * fulfilling the request made by the client.
 *
 */
@Component({
  selector: 'nxt-internal-server-error',
  standalone: true,
  imports: [TranslateModule, ErrorPageComponent, AsyncPipe, MatButtonModule, MatIconModule, NgIf, SharedUIModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './internal-server-error.component.html',
  styles: [
    `
      .error-page__image-500 {
        position: absolute;
        top: -250px;
        left: -85px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalServerErrorComponent {}
