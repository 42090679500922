import { ChangeDetectionStrategy, Component, computed, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Authentication } from '../../auth/store/auth.actions';
import { MatDialogRef, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { UserEmailNotificationSettings } from '@shared/api/user/user.types';
import UpdateUserNextOnlyAttributes = Authentication.UpdateUserNextOnlyAttributes;
import { FeatureToggleSelectors } from '@core/store/feature-toggles/feature-toggles.selectors';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { NgIf } from '@angular/common';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { DialogComponent } from '../../@shared/components/dialog/dialog.component';

@Component({
  selector: 'nxt-settings',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogComponent,
    MatTabGroup,
    MatTab,
    NgIf,
    EmailNotificationsComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsDialogComponent {
  isPageDirty = false;
  _emailNotificationSettings?: UserEmailNotificationSettings;

  featureToggles = toSignal(this.store.select(FeatureToggleSelectors.featureToggles));
  isEmailNotificationsAvailable = computed(() => this.featureToggles()?.get('USER_SETTINGS|EMAIL_NOTIFICATIONS_TAB') ?? false);

  get emailNotificationSettings(): { emailNotificationSettings: UserEmailNotificationSettings } | undefined {
    if (!this._emailNotificationSettings) {
      return;
    }

    return { emailNotificationSettings: { ...this._emailNotificationSettings } };
  }

  constructor(
    private actions$: Actions,
    private dialogRef: MatDialogRef<SettingsDialogComponent>,
    private store: Store
  ) {
    this.actions$
      .pipe(ofActionSuccessful(UpdateUserNextOnlyAttributes))
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onEmailNotificationSettingsChanged(event: UserEmailNotificationSettings) {
    if (!event) {
      return;
    }

    this._emailNotificationSettings = event;
    this.isPageDirty = true;
  }

  onSave() {
    if (!this.emailNotificationSettings) {
      return;
    }

    this.store.dispatch(new Authentication.UpdateUserNextOnlyAttributes({ ...this.emailNotificationSettings }));
  }
}
