import { importProvidersFrom, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DialogComposition } from './dialog.composite';
import { DialogDAO } from './dialog.dao';
import { DIALOG_ROUTES } from './dialog.routes';
import { NgxsModule } from '@ngxs/store';
import { QuillModule } from 'ngx-quill';
import { DialogState } from './store/dialog.state';

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(DIALOG_ROUTES)],
  providers: [
    DialogComposition,
    DialogDAO,
    importProvidersFrom(NgxsModule.forFeature([DialogState])),
    importProvidersFrom(QuillModule.forRoot()),
  ],
  exports: [],
})
export class DialogModule {}
