import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorService } from '@core/services/error.service';
import { environment } from '../../../build-environments/environment';
import { FileService } from '../services/file.service';
import { ErrorResponse } from '@shared/api/base/error-response.model';

@Injectable()
export class NextAPIErrorInterceptor implements HttpInterceptor {
  errorService = inject(ErrorService);
  fileService = inject(FileService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.error instanceof Blob && request.url?.includes(environment.apiBaseUrl)) {
          const reader = new FileReader();
          reader.readAsText(response.error);
          reader.onload = () => {
            try {
              this.errorService.handleError(request, {
                ...response,
                error: JSON.parse(reader.result as string),
              } as ErrorResponse);
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          };
          reader.onerror = (error) => {
            console.error('Error reading Blob:', error);
          };
        } else if (response instanceof HttpErrorResponse && request.url?.includes(environment.apiBaseUrl)) {
          this.errorService.handleError(request, response);
        }
        return throwError(() => response);
      })
    );
  }
}
