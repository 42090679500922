import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthCanActivateGuard, isAuthCanMatchGuard } from '@core/guards/auth.guard';
import { HomepageComponent } from './homepage/homepage.component';
import { RoutesConstants } from '@core/constants/routes.constants';
import { UnauthorizedComponent } from '../error-pages/unauthorized/unauthorized.component';
import { NotFoundComponent } from '../error-pages/not-found/not-found.component';
import { TranslateService } from '@ngx-translate/core';
import { LoggedOutComponent } from '@core/components/logged-out/logged-out.component';
import { UnauthorizedGuard } from '@core/guards/unauthorized.guard';
import { ForbiddenComponent } from '../error-pages/forbidden/forbidden.component';
import { AdfsUnauthorizedComponent } from '../error-pages/adfs-unauthorized/adfs-unauthorized.component';
import { InternalServerErrorComponent } from '../error-pages/internal-server-error/internal-server-error.component';
import { hasSelectedCompanyComponentGuard } from '@core/guards/has-selected-company.guard';
import { featureToggleGuard } from '@core/guards/feature-toggle.guard';

const routes: Routes = [
  {
    path: RoutesConstants.submissionsStatuses,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.SUBMISSION'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('SUBMISSIONS')],
    loadChildren: () => import('./submission/submission.routes').then((m) => m.SUBMISSION_ROUTES),
  },
  {
    path: RoutesConstants.provisions,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.PROVISION'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('PROVISIONS')],
    loadChildren: () => import('./provision/provision.routes').then((m) => m.PROVISION_ROUTES),
  },
  {
    path: RoutesConstants.workAreas,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.WORK_AREA'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('WORK_AREAS')],
    loadChildren: () =>
      import(
        /* webpackChunkName: "work-area" */
        './work-area/work-area.routes'
      ).then((m) => m.WORK_AREA_ROUTES),
  },
  {
    path: RoutesConstants.businessTopics,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.WORK_TOPIC'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('BUSINESS_TOPICS')],
    loadChildren: () => import('./work-area/business-topic/business-topic.routes').then((m) => m.BUSINESS_TOPIC_ROUTES),
  },
  {
    path: RoutesConstants.companies,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.COMPANY'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('COMPANIES')],
    loadChildren: () => import('./company/company.routes').then((m) => m.COMPANY_ROUTES),
  },
  {
    path: RoutesConstants.companyIdentifiers,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.COMPANY_IDENTIFIER'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('COMPANY_IDENTIFIERS')],
    loadChildren: () => import('./company-identifier/company-identifier.routes').then((m) => m.COMPANY_IDENTIFIER_ROUTES),
  },
  {
    path: RoutesConstants.nextOperations,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.NEXT_OPERATIONS'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('NEXT_OPERATIONS')],
    loadChildren: () => import('./next-operations/next-operations.routes').then((m) => m.NEXT_OPERATIONS_ROUTES),
  },
  {
    path: RoutesConstants.companyMasterDataImportRecommendations,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.COMPANY_MASTERDATA_IMPORT_RECOMMENDATION'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('IMPORTS')],
    loadChildren: () =>
      import('./company-masterdata-import-recommendation/company-masterdata-import-recommendation.routes').then(
        (m) => m.COMPANY_MASTERDATA_IMPORT_RECOMMENDATION_ROUTES
      ),
  },
  {
    path: RoutesConstants.companyMasterDataImportDecisions,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.COMPANY_MASTERDATA_IMPORT_DECISION'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('IMPORTS')],
    loadChildren: () =>
      import('./company-masterdata-import-decision/company-masterdata-decision.routes').then(
        (m) => m.COMPANY_MASTERDATA_DECISION_ROUTES
      ),
  },
  {
    path: RoutesConstants.companyMasterDataImportDelivery,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.COMPANY_MASTERDATA_IMPORT_DELIVERY'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('IMPORTS')],
    loadChildren: () =>
      import('./company-masterdata-import-delivery/company-masterdata-import-delivery.routes').then(
        (m) => m.COMPANY_MASTERDATA_DELIVERY_ROUTES
      ),
  },
  {
    path: RoutesConstants.dialog,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.DIALOG'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('DIALOG')],
    loadChildren: () => import('./dialog/dialog.routes').then((m) => m.DIALOG_ROUTES),
  },
  {
    path: RoutesConstants.businessTopicDialog,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.BUSINESS_TOPIC_DIALOG'),
    canMatch: [isAuthCanMatchGuard, featureToggleGuard('BUSINESS_TOPICS_DIALOG')],
    loadChildren: () =>
      import('./business-topic-dialog/business-topic-dialog.routes').then((m) => m.BUSINESS_TOPIC_DIALOG_ROUTES),
  },
  {
    path: RoutesConstants.user,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.USER'),
    canMatch: [isAuthCanMatchGuard],
    loadChildren: () => import('./user/user.routes').then((m) => m.USER_ROUTES),
  },
  {
    path: RoutesConstants.homepage,
    canActivate: [isAuthCanActivateGuard, hasSelectedCompanyComponentGuard],
    component: HomepageComponent,
  },
  {
    path: RoutesConstants.internalServerError,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.500'),
    component: InternalServerErrorComponent,
  },
  {
    path: RoutesConstants.forbidden,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.FORBIDDEN'),
    canActivate: [isAuthCanActivateGuard],
    component: ForbiddenComponent,
  },
  {
    path: RoutesConstants.unauthorized,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.UNAUTHORIZED'),
    component: UnauthorizedComponent,
  },
  {
    path: RoutesConstants.notFound,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.NOT_FOUND'),
    component: NotFoundComponent,
  },
  {
    path: RoutesConstants.loggedOut,
    canActivate: [UnauthorizedGuard],
    component: LoggedOutComponent,
  },
  {
    path: RoutesConstants.adfsUnauthorized,
    title: () => inject(TranslateService).instant('COMMON.ROUTES.ADFS_UNAUTHORIZED'),
    canActivate: [UnauthorizedGuard],
    component: AdfsUnauthorizedComponent,
  },

  { path: '', redirectTo: RoutesConstants.homepage, pathMatch: 'full' },
  { path: RoutesConstants.wildcard, redirectTo: RoutesConstants.notFound },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
