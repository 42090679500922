import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorPageComponent } from '@core/components/error-page/error-page.component';

/**
 * Not Authorized (401 Unauthorized):
 *
 * The 401 Unauthorized status code indicates that the request has not been applied because it lacks valid authentication
 * credentials for the target resource.
 * This status is returned when the server requires authentication but either the user did not provide credentials or the
 * provided credentials were invalid.
 * When a user encounters a "Not Authorized" error, it usually means they need to authenticate themselves (e.g., log in with a username and password) before accessing the requested resource.
 *
 */
@Component({
  selector: 'nxt-unauthorized',
  standalone: true,
  imports: [TranslateModule, ErrorPageComponent],
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent {}
