<nxt-dialog>
  <nxt-modal-header>
    <div class="nxt-blue-marker">{{"USER.SETTINGS.MODAL_TITLE" | translate}}</div>
  </nxt-modal-header>
  <nxt-modal-body>
    <mat-tab-group dynamicHeight mat-align-tabs="start" selectedIndex="3">
      <mat-tab [label]="'USER.SETTINGS.TAB_GENERAL_TITLE' | translate" disabled
               [attr.aria-label]="'USER.SETTINGS.TAB_GENERAL_TITLE' | translate">
      </mat-tab>
      <mat-tab [label]="'USER.SETTINGS.TAB_COLOR_TITLE' | translate" disabled
               [attr.aria-label]="'USER.SETTINGS.TAB_COLOR_TITLE' | translate">
      </mat-tab>
      <mat-tab [label]="'USER.SETTINGS.TAB_START_PAGE_TITLE' | translate" disabled
               [attr.aria-label]="'USER.SETTINGS.TAB_START_PAGE_TITLE' | translate">
      </mat-tab>
      <mat-tab
        *ngIf="isEmailNotificationsAvailable()"
        [label]="'USER.SETTINGS.TAB_EMAIL_TITLE' | translate"
        [attr.aria-label]="'USER.SETTINGS.TAB_EMAIL_TITLE' | translate">
        <nxt-email-notifications (emailNotificationSettingsChanged)="onEmailNotificationSettingsChanged($event)"></nxt-email-notifications>
      </mat-tab>
    </mat-tab-group>
  </nxt-modal-body>
  <nxt-modal-footer>
    <mat-dialog-actions>
      <button class="m-3"
              data-test-id="settings-modal-cancel-btn"
              mat-stroked-button
              color="primary"
              [mat-dialog-close]="false"
              [attr.aria-label]="'COMMON.CANCEL' | translate">
        {{'COMMON.CANCEL' | translate}}
      </button>
      <button class="my-3"
              mat-raised-button
              color="primary"
              (click)="onSave()"
              [disabled]="!isPageDirty"
              [attr.aria-label]="'COMMON.SAVE' | translate">
        {{'COMMON.SAVE' | translate}}
      </button>
    </mat-dialog-actions>
  </nxt-modal-footer>
</nxt-dialog>
