import { inject } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/@core/services/auth.service';
import { RoutesConstants } from '../constants/routes.constants';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

export const UnauthorizedGuard = () => {
  const authService = inject(AuthService);
  const store = inject(Store);

  return authService.isAuthenticationDone$.pipe(
    switchMap((isAuthenticated) => {
      if (isAuthenticated && authService.hasValidAccessToken) {
        store.dispatch(new Navigate([RoutesConstants.homepageRelative]));
        return of(false);
      }

      return of(true);
    })
  );
};
