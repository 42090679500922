import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ErrorPageComponent } from '@core/components/error-page/error-page.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'nxt-not-found',
  standalone: true,
  imports: [ErrorPageComponent, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './not-found.component.html',
  styles: [
    `
      .not-found__image {
        position: absolute;
        top: -50px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {}
