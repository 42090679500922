import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { ErrorPageComponent } from '@core/components/error-page/error-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { Store } from '@ngxs/store';
import { Authentication } from '../../app/auth/store/auth.actions';

@Component({
  selector: 'nxt-adfs-unauthorized',
  standalone: true,
  imports: [ErrorPageComponent, TranslateModule, MatButtonModule, MatIconModule, NgIf],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './adfs-unauthorized.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdfsUnauthorizedComponent {
  private store = inject(Store);
  login() {
    this.store.dispatch(new Authentication.Login());
  }
}
