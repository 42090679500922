<div class="ng-next-header" [@changeHeight]="currentState">
  @if (isProd) {
    <mat-icon
      *ngIf="showLogo(); else imagePlaceHolder"
      class="next-logo"
      [attr.alt]="'COMMON.AXA.ICON.HEADER.LOGO' | translate"
      aria-hidden="false"
      svgIcon="next_logo"
      [routerLink]="[routeConstants.homepageRelative]" />
  } @else {
    <img
      *ngIf="showLogo(); else imagePlaceHolder"
      class="next-logo"
      src="img/Next_Logo_test.png"
      [attr.alt]="'COMMON.AXA.ICON.HEADER.LOGO' | translate"
      height="16"
      width="16"
      aria-hidden="false"
      [routerLink]="[routeConstants.homepageRelative]" />
  }
    <ng-template #imagePlaceHolder>
      <div class="next-logo-placeholder"></div>
    </ng-template>

  <ng-container
    *ngIf="showHeaderItems() && (hasSelectedCompany$ | async); else onlyLogoutTmpl">
    <mat-toolbar>
      <button
        class="next-menu-btn"
        *ngIf="showMenu()"
        data-test-id="next-menu-btn"
        mat-button
        [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
          {{'COMMON.AXA.ICON.HEADER.MENU' | translate}}
      </button>
      <mat-menu #menu class="sharp-edges">
        <nav>
          <button
            *ngIf="isSubmissionsButtonAvailable()"
            data-test-id="menuSubmissionsBtn"
            [attr.aria-label]="'COMMON.MENU.SUBMISSIONS' | translate"
            [routerLink]="routeConstants.submissionsStatusesRelative"
            mat-menu-item>{{ 'COMMON.MENU.SUBMISSIONS' | translate }}
          </button>
          <button
            *ngIf="isProvisionsButtonAvailable()"
            data-test-id="menuProvisionBtn"
            [attr.aria-label]="'PROVISION.PLURAL'| translate"
            [routerLink]="routeConstants.provisionsRelative"
            mat-menu-item>{{'PROVISION.PLURAL' | translate}}
          </button>
          <button
            *ngIf="isDialogButtonAvailable()"
            data-test-id="menuDialogBtn"
            [attr.aria-label]="'COMMON.MENU.DIALOG'| translate"
            [routerLink]="routeConstants.dialogRelative"
            mat-menu-item>{{'COMMON.MENU.DIALOG' | translate}}
          </button>
          <button
            *ngIf="isWorkAreasButtonAvailable()"
            data-test-id="menuWorkAreasBtn"
            [attr.aria-label]="'COMMON.MENU.WORK_AREAS'| translate"
            [routerLink]="routeConstants.workAreasRelative"
            mat-menu-item>{{'COMMON.MENU.WORK_AREAS' | translate}}
          </button>
          <button
            *ngIf="isBusinessTopicsButtonAvailable()"
            data-test-id="menuBusinessTopicBtn"
            [attr.aria-label]="'COMMON.MENU.BUSINESS_TOPIC' | translate"
            [routerLink]="routeConstants.businessTopicsRelative"
            mat-menu-item>{{'COMMON.MENU.BUSINESS_TOPIC' | translate}}
          </button>
          <button
            *ngIf="isCompaniesButtonAvailable()"
            data-test-id="menuCompanyBtn"
            [attr.aria-label]="'COMMON.MENU.COMPANY'| translate"
            [routerLink]="routeConstants.companiesRelative"
            mat-menu-item>{{'COMMON.MENU.COMPANY' | translate}}
          </button>
          <button
            *ngIf="isCompanyIdentifiersButtonAvailable()"
            data-test-id="menuCompanyIdentifiersBtn"
            [attr.aria-label]="'COMMON.MENU.COMPANY_IDENTIFIERS' | translate"
            [routerLink]="routeConstants.companyIdentifiersRelative"
            mat-menu-item>
              {{'COMMON.MENU.COMPANY_IDENTIFIERS' | translate}}
          </button>
          <button
            *ngIf="isNextOperationsSettingsButtonAvailable()"
            data-test-id="menuNextOperationsSettingsBtn"
            [attr.aria-label]="'COMMON.MENU.NEXT_OPERATIONS_SETTINGS' | translate"
            [routerLink]="routeConstants.nextOperationsRelative"
            mat-menu-item>
              {{'COMMON.MENU.NEXT_OPERATIONS_SETTINGS' | translate}}
          </button>
          <ng-container *ngIf="areImportsButtonsAvailable()">
            <button data-test-id="menuCompanyMasterDataImportRecommendationBtn"
                    [attr.aria-label]="'COMMON.MENU.COMPANY_MASTERDATA_IMPORT_RECOMMENDATION' | translate"
                    [routerLink]="routeConstants.companyMasterDataImportRecommendationsRelative"
                    mat-menu-item>
                      {{'COMMON.MENU.COMPANY_MASTERDATA_IMPORT_RECOMMENDATION' | translate}}
            </button>
            <button data-test-id="menuCompanyMasterDataImportRecommendationDecisionBtn"
                    [attr.aria-label]="'COMMON.MENU.COMPANY_MASTERDATA_IMPORT_DECISION' | translate"
                    [routerLink]="routeConstants.companyMasterDataImportDecisionsRelative"
                    mat-menu-item>
                      {{'COMMON.MENU.COMPANY_MASTERDATA_IMPORT_DECISION' | translate}}
            </button>
            <button data-test-id="menuCompanyMasterDataImportDeliveryBtn"
                    [attr.aria-label]="'COMMON.MENU.COMPANY_MASTERDATA_IMPORT_DELIVERY' | translate"
                    [routerLink]="routeConstants.companyMasterDataImportDeliveriesRelative"
                    mat-menu-item>
                      {{'COMMON.MENU.COMPANY_MASTERDATA_IMPORT_DELIVERY' | translate}}
            </button>
          </ng-container>
          <button
            *ngIf="isBusinessTopicDialogConfigsButtonAvailable()"
            data-test-id="menuBusinessTopicDialogConfigsBtn"
            [attr.aria-label]="'COMMON.MENU.BUSINESS_TOPIC_DIALOG_CONFIGS' | translate"
            [routerLink]="routeConstants.businessTopicDialogRelative"
            mat-menu-item>
              {{'COMMON.MENU.BUSINESS_TOPIC_DIALOG_CONFIGS' | translate}}
          </button>
        </nav>
      </mat-menu>

      <nxt-language-selector class="ms-auto h-100"/>

      <button
        mat-button
        class="help-btn"
        data-test-id="help-btn"
        [attr.aria-label]="'COMMON.AXA.ICON.HEADER.HELP' | translate">
        <mat-icon>help</mat-icon>
        {{'COMMON.AXA.ICON.HEADER.HELP' | translate}}
      </button>

      <nxt-profile-menu *ngIf="(userProfile$ | async) as userProfile" [userProfile]="userProfile" [userType]="userType()"/>
      <ng-container *ngTemplateOutlet="logoutBtn"/>
    </mat-toolbar>
  </ng-container>
</div>

<ng-template #onlyLogoutTmpl>
  <div class="d-flex justify-content-end align-items-center w-100 h-100">
    <nxt-language-selector class="ms-auto h-100"/>
    <ng-container *ngTemplateOutlet="logoutBtn"/>
  </div>
</ng-template>

<ng-template #logoutBtn>
  <button
    *ngIf="showLogout()"
    (click)="logout()"
    mat-stroked-button
    data-test-id="logout-btn"
    color="primary"
    class="logout-btn m1-auto"
    [attr.aria-label]="'COMMON.AXA.ICON.HEADER.LOGOUT' | translate">
      <mat-icon class="material-icons-outlined">logout</mat-icon>
      {{'COMMON.AXA.ICON.HEADER.LOGOUT' | translate}}
  </button>
</ng-template>
