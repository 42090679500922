import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { AuthService } from '@core/services/auth.service';
import { AUTH_CONFIG } from './auth.config';
import { AUTH_MODULE_CONFIG } from './auth-module-config';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './store/auth.state';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  // DISCUSS: can we split tokens in different storages
  // return new CustomOAuthStorage();
  // return sessionStorage;
  return localStorage;
}

@NgModule({
  declarations: [],
  imports: [CommonModule, OAuthModule.forRoot(), NgxsModule.forFeature([AuthState])],
  providers: [],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService, Location], multi: true },
        { provide: AuthConfig, useValue: AUTH_CONFIG },
        { provide: OAuthModuleConfig, useValue: AUTH_MODULE_CONFIG },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
