import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthStateSelectors } from '../../../auth/store/auth.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DEFAULT_EMAIL_NOTIFICATION_SETTINGS } from '@core/constants/global.constants';
import { map } from 'rxjs';
import { UserEmailNotificationSettings } from '@shared/api/user/user.types';
import { TranslateModule } from '@ngx-translate/core';
import { StatusLegendComponent } from '../../../@shared/components/status-legend/status-legend.component';
import { MatDivider } from '@angular/material/divider';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { InfoPanelComponent } from '../../../@shared/components/info-panel/info-panel.component';

@Component({
  selector: 'nxt-email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrls: ['./email-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InfoPanelComponent,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatIcon,
    MatSlideToggle,
    ReactiveFormsModule,
    FormsModule,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatDivider,
    StatusLegendComponent,
    TranslateModule,
  ],
})
export class EmailNotificationsComponent implements OnInit {
  private readonly INPUT_VALIDATION = 'INPUT_VALIDATION';
  private readonly PLAUSIBILITY_CHECK = 'PLAUSIBILITY_CHECK';
  private readonly FUNCTIONAL_VALIDATION = 'FUNCTIONAL_VALIDATION';
  private readonly NEW_PROVISION = 'NEW_PROVISION';
  private readonly NEW_DIALOG = 'NEW_DIALOG';
  private readonly NEW_MESSAGE_IN_DIALOG = 'NEW_MESSAGE_IN_DIALOG';
  private readonly ARCHIVING = 'ARCHIVING';

  submissionStatusColumns = ['id', 'isActiveNegative', 'isActivePositive'];
  submissionStatusData: { id: string; isActiveNegative: boolean; isActivePositive: boolean }[] = [];

  provisionColumns = ['id', 'isActive'];
  provisionData: { id: string; isActive: boolean }[] = [];

  dialogColumns = ['id', 'isActive'];
  dialogData: { id: string; isActive: boolean }[] = [];

  emailNotificationSettings$ = this.store.select(AuthStateSelectors.emailNotificationSettings).pipe(takeUntilDestroyed());
  emailNotificationSettings: UserEmailNotificationSettings = DEFAULT_EMAIL_NOTIFICATION_SETTINGS;

  @Output() emailNotificationSettingsChanged = new EventEmitter<UserEmailNotificationSettings>();

  constructor(private store: Store) {
    this.emailNotificationSettings$
      .pipe(map((settings) => settings ?? DEFAULT_EMAIL_NOTIFICATION_SETTINGS))
      .subscribe((settings) => {
        this.emailNotificationSettings = { ...settings };
      });
  }
  ngOnInit() {
    this.initTableData();
  }

  private initTableData() {
    this.submissionStatusData = [
      {
        id: this.INPUT_VALIDATION,
        isActiveNegative: this.emailNotificationSettings.inputValidationNegative,
        isActivePositive: this.emailNotificationSettings.inputValidationPositive,
      },
      {
        id: this.PLAUSIBILITY_CHECK,
        isActiveNegative: this.emailNotificationSettings.plausibilityCheckNegative,
        isActivePositive: this.emailNotificationSettings.plausibilityCheckPositive,
      },
      {
        id: this.FUNCTIONAL_VALIDATION,
        isActiveNegative: this.emailNotificationSettings.functionalValidationNegative,
        isActivePositive: this.emailNotificationSettings.functionalValidationPositive,
      },
    ];

    this.provisionData = [{ id: this.NEW_PROVISION, isActive: this.emailNotificationSettings.newProvision }];

    this.dialogData = [
      { id: this.NEW_DIALOG, isActive: this.emailNotificationSettings.newDialog },
      { id: this.NEW_MESSAGE_IN_DIALOG, isActive: this.emailNotificationSettings.newMessageInDialog },
      { id: this.ARCHIVING, isActive: this.emailNotificationSettings.archiving },
    ];
  }

  onChange() {
    this.updateSubmissionStatusSettings();
    this.emailNotificationSettings.newProvision = this.provisionData[0].isActive;
    this.updateDialogSettings();
    this.emailNotificationSettingsChanged.emit(this.emailNotificationSettings);
  }

  private updateSubmissionStatusSettings() {
    this.submissionStatusData.forEach((x) => {
      switch (x.id) {
        case this.INPUT_VALIDATION:
          this.emailNotificationSettings.inputValidationPositive = x.isActivePositive;
          this.emailNotificationSettings.inputValidationNegative = x.isActiveNegative;
          break;
        case this.FUNCTIONAL_VALIDATION:
          this.emailNotificationSettings.functionalValidationPositive = x.isActivePositive;
          this.emailNotificationSettings.functionalValidationNegative = x.isActiveNegative;
          break;
        case this.PLAUSIBILITY_CHECK:
          this.emailNotificationSettings.plausibilityCheckPositive = x.isActivePositive;
          this.emailNotificationSettings.plausibilityCheckNegative = x.isActiveNegative;
          break;
      }
    });
  }

  private updateDialogSettings() {
    this.dialogData.forEach((x) => {
      switch (x.id) {
        case this.NEW_DIALOG:
          this.emailNotificationSettings.newDialog = x.isActive;
          break;
        case this.NEW_MESSAGE_IN_DIALOG:
          this.emailNotificationSettings.newMessageInDialog = x.isActive;
          break;
        case this.ARCHIVING:
          this.emailNotificationSettings.archiving = x.isActive;
          break;
      }
    });
  }
}
