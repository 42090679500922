<nxt-error-page hideToggle="true" emailLine1TransKey="ERROR_PAGE.500.EMAIL.LINE1">
  <nxt-error-page__title>{{ 'ERROR_PAGE.500.TITLE'| translate }}</nxt-error-page__title>
  <nxt-error-page__description_title>{{ 'ERROR_PAGE.500.SUBTITLE'| translate }}</nxt-error-page__description_title>
  <nxt-error-page__description_content>
    {{ 'ERROR_PAGE.500.DESC.LINE1'| translate }}<br>
    {{ 'ERROR_PAGE.500.DESC.LINE2'| translate }}<br>
  </nxt-error-page__description_content>
  <nxt-error-page__image>
    <img src="img/error-page__500.svg"
         class="error-page__image-500"
         [attr.alt]="'ERROR_PAGE.500.IMAGE.PLACEHOLDER' | translate"
         height="740"
         width="903">
  </nxt-error-page__image>
</nxt-error-page>
