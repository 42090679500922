<nxt-error-page>
  <nxt-error-page__title> {{ 'ERROR_PAGE.FORBIDDEN.TITLE'| translate }}</nxt-error-page__title>
  <nxt-error-page__description_title> {{ 'ERROR_PAGE.FORBIDDEN.SUBTITLE'| translate }}</nxt-error-page__description_title>
  <nxt-error-page__description_content>
    {{ 'ERROR_PAGE.FORBIDDEN.DESC.LINE1'| translate }}<br>
    {{ 'ERROR_PAGE.FORBIDDEN.DESC.LINE2'| translate }}<br>
  </nxt-error-page__description_content>

  <nxt-error-page__image>
    <img src="img/error-page__403-adfs.svg"
         [attr.alt]="'ERROR_PAGE.FORBIDDEN.IMAGE.PLACEHOLDER' | translate"
         height="500"
         width="700">
  </nxt-error-page__image>
</nxt-error-page>
