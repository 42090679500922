<div class="container">
    <div class="row image-row">
      <div class="col d-flex justify-content-center align-items-center">
        @if (isProd) {
          <mat-icon
            class="next-logo"
            [attr.alt]="'COMMON.AXA.ICON.HEADER.LOGO' | translate"
            aria-hidden="false"
            svgIcon="next_logo" />
        } @else {
          <img
            class="next-logo"
            src="img/Next_Logo_test.png"
            [attr.alt]="'COMMON.AXA.ICON.HEADER.LOGO' | translate"
            height="16"
            width="16" aria-hidden="false" />
          }
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <h2 class="nxt-blue-marker">
          {{ 'LOGGED_OUT.GOODBYE'| translate }}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <p class="logged-out-txt nxt-accent-900">
          <span>{{ 'LOGGED_OUT.SUCCESS_MESSAGE'| translate }}</span>
        </p>
      </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center align-items-center">
            <button class="login-btn" mat-raised-button color="primary" (click)="login()">
              {{ 'LOGGED_OUT.LOGIN'| translate }}
            </button>
        </div>
      </div>
</div>
