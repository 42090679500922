import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { LanguageService, NextLanguage } from '@core/services/language.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'nxt-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, TranslateModule],
})
export class LanguageSelectorComponent {
  languageService = inject(LanguageService);
  translateService = inject(TranslateService);
  targetLanguage = computed<NextLanguage>(() => (this.languageService.currentLanguage() === 'de' ? 'en' : 'de'));

  switchLanguage() {
    this.languageService.setLanguage(this.targetLanguage());
  }
}
