import { Component, HostListener, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { RouterState } from '@ngxs/router-plugin';
import { RoutesConstants } from '@core/constants/routes.constants';
import { SseService } from '@shared/api/server-sent-events/sse.service';
import { LanguageService } from '@core/services/language.service';
import { AsyncPipe } from '@angular/common';
import { FooterComponent } from './@core/components/layout/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './@core/components/layout/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/build-environments/environment';

@Component({
  selector: 'nxt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent, AsyncPipe, TranslateModule],
})
export class AppComponent implements OnDestroy {
  currentRelativeRouteUrl$ = this.store.select(RouterState.url);
  routeConstants = RoutesConstants;

  @HostListener('window:beforeunload', [])
  onBeforeUnloadWindow() {
    this.sseService.closeConnection();
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private store: Store,
    private sseService: SseService,
    private languageService: LanguageService
  ) {
    this.registerSvgIcons();
    sseService.connect();
    languageService.init();
  }

  focusMainContent($event: any) {
    $event.preventDefault();
    document.getElementById('skip-link-target')?.focus();
  }

  ngOnDestroy() {
    this.sseService.closeConnection();
  }

  private registerSvgIcons() {
    this.matIconRegistry.addSvgIcon('open_all', this.domSanitizer.bypassSecurityTrustResourceUrl('img/open_all.svg'));
    this.matIconRegistry.addSvgIcon('close_all', this.domSanitizer.bypassSecurityTrustResourceUrl('img/close_all.svg'));
    if (environment.production === 'true') {
      this.matIconRegistry.addSvgIcon('next_logo', this.domSanitizer.bypassSecurityTrustResourceUrl('img/NExt-Logo_norm_2C.svg'));
    }
  }
}
