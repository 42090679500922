<button
    class="profile-btn"
    mat-button
    [attr.aria-label]="'COMMON.AXA.ICON.HEADER.PROFILE' | translate"
    [matMenuTriggerFor]="profileMenu">
        <mat-icon>person</mat-icon>
        <span>{{ ('COMMON.AXA.ICON.HEADER.PROFILE' | translate) }}<span *ngIf="userProfile.numberOfCompanies > 1"> {{userProfile.selectedCompanyIndex}}</span></span>
</button>

<mat-menu
    #profileMenu
    class="profile-menu sharp-edges">
    <button
        mat-menu-item
        data-test-id="profile-user-company-btn"
        [matMenuTriggerFor]="userProfile.numberOfCompanies > 1 ? switchCompanyMenu : null"
        [class.no-pointer] = "!(userProfile.numberOfCompanies > 1)">
            <mat-icon class="profile-menu-item-icon">person</mat-icon>
            <div class="d-flex flex-column profile-menu-item-text">
                <span class="profile-menu-item-title">{{ userProfile.fullName }}</span>
                <span class="mat-caption">{{ ('COMMON.PROFILE_MENU.USER_ID' | translate) + ': '  + userProfile.id }}</span>
                    <span class="profile-menu-item-company-title subtitle-1">
                        {{ 'COMMON.PROFILE_MENU.COMPANY' | translate }}
                        <span *ngIf="userProfile.numberOfCompanies > 1">
                            ({{userProfile.selectedCompanyIndex}} {{ 'COMMON.PROFILE_MENU.FROM' | translate }} {{ userProfile.numberOfCompanies }})
                        </span>
                    </span>
                <span class="mat-caption">{{ userProfile.selectedCompanyName }}</span>
            </div>
    </button>
    <mat-divider/>
    <ng-container *ngIf="userType | nxtFunction:isExternalUser">
        <a class="pe-0" href="{{ profileUrl }}" target="_blank" data-test-id="profile-data-btn" mat-menu-item>
            <mat-icon class="profile-menu-item-icon">badge</mat-icon>
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-column profile-menu-item-text">
                    <span class="profile-menu-item-title">{{ 'COMMON.PROFILE_MENU.PROFILE_DATA' | translate }}</span>
                    <span class="mat-caption">{{ 'COMMON.PROFILE_MENU.PROFILE_DATA_DESCRIPTION' | translate }}</span>
                </div>
                <mat-icon class="external-app-icon nxt-primary-color">open_in_new</mat-icon>
            </div>
        </a>
        <mat-divider/>
    </ng-container>

    <!-- ENABLE, if some functionality for this item is implemented. -->
    <!--
    <button data-test-id="profile-auth-btn" mat-menu-item>
      <mat-icon class="profile-menu-item-icon">lock</mat-icon>
      <div class="d-flex flex-column profile-menu-item-text">
          <span class="profile-menu-item-title">{{ 'COMMON.PROFILE_MENU.AUTHORIZATION' | translate }}</span>
          <span class="mat-caption">{{ 'COMMON.PROFILE_MENU.AUTHORIZATION_DESCRIPTION' | translate }}</span>
      </div>
    </button>
    <mat-divider/>
    -->
    <ng-container *ngIf="userType | nxtFunction:userManagementItemAvailable">
      <a class="pe-0" href="{{ userManagementUrl }}" target="_blank" data-test-id="user-management-btn" mat-menu-item>
        <mat-icon class="profile-menu-item-icon">groups</mat-icon>
        <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-column profile-menu-item-text">
                <span class="profile-menu-item-title">{{ 'COMMON.PROFILE_MENU.USER_MANAGEMENT' | translate }}</span>
                <span class="mat-caption">{{ 'COMMON.PROFILE_MENU.USER_MANAGEMENT_DESCRIPTION' | translate }}</span>
            </div>
            <mat-icon class="external-app-icon nxt-primary-color">open_in_new</mat-icon>
        </div>
      </a>
      <mat-divider/>
    </ng-container>
    <!-- ENABLE, if details page is implemented. UserManagement Item will then be removed!-->
    <!--
    <button data-test-id="profile-groups-btn" mat-menu-item class="profile-menu-item">
        <mat-icon class="profile-menu-item-icon">groups</mat-icon>
        <div class="d-flex flex-column profile-menu-item-text">
            <span class="profile-menu-item-title">{{ 'COMMON.PROFILE_MENU.OVERVIEW' | translate }}</span>
            <span class="mat-caption">{{ 'COMMON.PROFILE_MENU.OVERVIEW_DESCRIPTION' | translate }}</span>
        </div>
    </button>
    -->
    <ng-container *ngIf="userType | nxtFunction:isExternalUser">
      <mat-divider/>
      <button
          data-test-id="profile-settings-btn"
          class="settings-btn"
          mat-menu-item
          (click)="openSettings()">
          <mat-icon class="profile-menu-item-icon">settings</mat-icon>
          <div class="d-flex flex-column profile-menu-item-text">
              <span class="profile-menu-item-title">{{ 'COMMON.PROFILE_MENU.SETTINGS' | translate }}</span>
              <span class="mat-caption">{{ 'COMMON.PROFILE_MENU.SETTINGS_DESCRIPTION' | translate }}</span>
          </div>
      </button>
    </ng-container>
    <mat-divider/>
</mat-menu>

<mat-menu
    #switchCompanyMenu
    class="profile-menu sharp-edges">
        <ng-container *ngIf="(currentCompany$ | async) as currentCompany">
            <ng-container *ngFor="let company of userCompanies$ | async; let i = index">
                <button
                    mat-menu-item
                    data-test-id="company-switch-btn"
                    (click)="switchCompany(userProfile.id, company.businessKey?.nextId, currentCompany.businessKey?.nextId)"
                    [class.no-pointer] = "(i + 1) === currentCompany.companyIndex">
                        <div class="d-flex profile-submenu-item">
                            <div class="profile-submenu-marker-container">
                                <div
                                    class="profile-submenu-marker-colored"
                                    [class.hidden] = "(i + 1) !== currentCompany.companyIndex"
                                ></div>
                            </div>
                            <p class="profile-submenu-content-counter">{{ i + 1 }}. </p>
                            <p class="profile-submenu-content-company">{{ company.name }}</p>
                        </div>
                </button>
                <mat-divider/>
            </ng-container>
        </ng-container>
</mat-menu>
