import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorPageComponent } from '@core/components/error-page/error-page.component';
import { JsonPipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

/**
 * Forbidden (403 Forbidden):
 *
 * The 403 Forbidden status code indicates that the server understood the request but refuses to authorize it.
 * This status is typically used when the server recognizes the user's identity and credentials,
 * but the user doesn't have permission to access the requested resource.
 * A "Forbidden" error page would typically inform the user that they are not allowed to access the
 * specific resource they requested due to permission restrictions.
 *
 */
@Component({
  selector: 'nxt-forbidden',
  standalone: true,
  imports: [TranslateModule, ErrorPageComponent, JsonPipe, NgIf, MatIconModule, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './forbidden.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenComponent {}
