<div class="my-3 ps-3">
  <nxt-info-panel translationKey="USER.SETTINGS.TAB_EMAIL.HINT" [showIcon]="false" />
</div>

<table mat-table [dataSource]="submissionStatusData" class="ps-3">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>{{'USER.SETTINGS.TAB_EMAIL.SUBMISSION_SETTING_NAME' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{ 'USER.SETTINGS.TAB_EMAIL.' + element.id | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="isActiveNegative">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex align-items-center">
        <span class="pe-3">{{'USER.SETTINGS.TAB_EMAIL.IS_ACTIVE_POSITIVE_NEGATIVE' | translate}}</span>
        <mat-icon class="nxt-error-color pe-3" [attr.aria-hidden]="null">close</mat-icon>
        <mat-icon class="nxt-touch_app-status pe-3" [attr.aria-hidden]="null">touch_app</mat-icon>
        <mat-icon class="icon-warning-status material-icons-outlined pe-3" [attr.aria-hidden]="null">bolt</mat-icon>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isActiveNegative" [aria-label]="'slide-toggle'"
                        (ngModelChange)="onChange()"
                        color="primary"/>
    </td>
  </ng-container>

  <ng-container matColumnDef="isActivePositive">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex align-items-center">
        <span class="pe-3">{{'USER.SETTINGS.TAB_EMAIL.IS_ACTIVE_POSITIVE_NEGATIVE' | translate}}</span>
        <mat-icon class="icon-pending-status material-icons-outlined  pe-3" [attr.aria-hidden]="null">hourglass_top
        </mat-icon>
        <mat-icon class="nxt-success-status pe-3" [attr.aria-hidden]="null">done</mat-icon>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isActivePositive" [aria-label]="'slide-toggle'"
                        (ngModelChange)="onChange()"
                        color="primary"/>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="submissionStatusColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: submissionStatusColumns"></tr>
</table>

<mat-divider/>

<table mat-table [dataSource]="provisionData" class="ps-3">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>{{'USER.SETTINGS.TAB_EMAIL.PROVISION_SETTING_NAME' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{ 'USER.SETTINGS.TAB_EMAIL.' + element.id | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="isActive">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex align-items-center">
        <span class="pe-3">{{'USER.SETTINGS.TAB_EMAIL.IS_ACTIVE' | translate}}</span>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isActive" [aria-label]="'slide-toggle'"
                        (ngModelChange)="onChange()"
                        color="primary"/>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="provisionColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: provisionColumns"></tr>
</table>

<mat-divider/>

<table mat-table [dataSource]="dialogData" class="ps-3">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>{{'USER.SETTINGS.TAB_EMAIL.DIALOG_SETTING_NAME' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{ 'USER.SETTINGS.TAB_EMAIL.' + element.id | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="isActive">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex align-items-center">
        <span class="pe-3">{{'USER.SETTINGS.TAB_EMAIL.IS_ACTIVE' | translate}}</span>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isActive" [aria-label]="'slide-toggle'"
                        (ngModelChange)="onChange()"
                        color="primary"/>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="dialogColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: dialogColumns"></tr>
</table>

<mat-divider/>
<div class="status-legend px-2 py-2">
  <nxt-status-legend [statusValues]="['ERROR','ACTION_REQUIRED', 'WARNING', 'PENDING', 'OK']"/>
</div>

