import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { UserProfile } from '../../../../../user/user.view.types';
import { UniqueIDString } from '@shared/string-alias-types';
import { Authentication } from '../../../../../auth/store/auth.actions';
import { Store } from '@ngxs/store';
import { AuthStateSelectors } from '../../../../../auth/store/auth.selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsDialogComponent } from '../../../../../user/settings-dialog/settings-dialog.component';
import { EXTERNAL_USER_TYPE_LIST } from '@core/constants/global.constants';
import { UserType } from '@shared/api/user/user.types';
import { environment } from '../../../../../../build-environments/environment';
import { FunctionPipe } from '@shared/pipes/function.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { AuthState } from 'src/app/auth/store/auth.state';
import { FeatureToggleSelectors } from '@core/store/feature-toggles/feature-toggles.selectors';

@Component({
  selector: 'nxt-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButton,
    MatMenuTrigger,
    MatIcon,
    NgIf,
    MatMenu,
    MatMenuItem,
    MatDivider,
    NgFor,
    AsyncPipe,
    TranslateModule,
    FunctionPipe,
  ],
})
export class ProfileMenuComponent {
  private store = inject(Store);
  private dialog = inject(MatDialog);

  @Input({ required: true }) userProfile!: UserProfile;
  @Input() userType?: UserType;
  profileUrl = `${environment.iamBaseUrl}${environment.iamExtProfileRelative}`;
  userManagementUrl = `${environment.iamBaseUrl}${environment.iamUserManagementRelative}`;
  userCompanies$ = this.store.select(AuthStateSelectors.userCompanies);
  currentCompany$ = this.store.select(AuthStateSelectors.selectedCompany);

  isExternalUser = (userType: UserType) => EXTERNAL_USER_TYPE_LIST.includes(userType);

  userManagementItemAvailable = () =>
    this.store.selectSnapshot(FeatureToggleSelectors.featureToggles).get('HEADER|USER_MANAGEMENT_BUTTON') === true;

  switchCompany(
    userId: UniqueIDString<'User'>,
    targetCompanyId?: UniqueIDString<'Company'>,
    sourceCompanyId?: UniqueIDString<'Company'>
  ) {
    if (!targetCompanyId || !userId || targetCompanyId === sourceCompanyId) {
      return;
    }

    this.store.dispatch(
      new Authentication.UpdateSelectedCompany({ selectedCompanyId: targetCompanyId, showSuccessMessage: true })
    );
  }

  openSettings(): MatDialogRef<SettingsDialogComponent, any> | void {
    if (this.store.selectSnapshot(AuthStateSelectors.isInternalUser)) {
      return;
    }

    return this.dialog.open(SettingsDialogComponent);
  }
}
