<nxt-error-page showSupportMail="false">
  <nxt-error-page__title>{{ 'ERROR_PAGE.ADFS.TITLE'| translate }}</nxt-error-page__title>
  <nxt-error-page__description_title>{{ 'ERROR_PAGE.ADFS.SUBTITLE'| translate }}</nxt-error-page__description_title>
  <nxt-error-page__description_content>
    {{ 'ERROR_PAGE.ADFS.DESC.LINE1'| translate }}<br>
    <div class="d-flex align-items-center">
      {{ 'ERROR_PAGE.ADFS.DESC.LINE2'| translate }}
      <button class="text-decoration-underline" mat-button color="primary" (click)="login()">
        {{ 'ERROR_PAGE.ADFS.DESC.LINK.TEXT'| translate }}
      </button>
      {{ 'ERROR_PAGE.ADFS.DESC.LINE3'| translate }}
    </div>
  </nxt-error-page__description_content>
  <nxt-error-page__image>
    <img src="img/error-page__403-adfs.svg"
         [attr.alt]="'ERROR_PAGE.ADFS.IMAGE.PLACEHOLDER' | translate"
         height="500"
         width="700">
  </nxt-error-page__image>
</nxt-error-page>
