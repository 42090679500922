<div class="error-page">

  <div class="error-page__container nxt-accent-bg__100">
    <div class="d-flex align-items-center">
      <div class="error-page__title nxt-blue-marker">
        <div *ngIf="!!techInfo?.apiStatusCode">{{ techInfo.apiStatusCode }}</div>
        <ng-content select="nxt-error-page__title" />
      </div>
    </div>

    <div class="error-page__description">
      <div class="error-page__description_title">
        <h3 class="nxt-blue-marker">
          <ng-content select="nxt-error-page__description_title" />
        </h3>
      </div>
      <mat-divider class="w-100" />

      <p class="error-page__description_content">
        <ng-content select="nxt-error-page__description_content" />
        <br>
        <ng-container *ngIf="showSupportMail">
          {{ (emailLine1TransKey ?? 'ERROR_PAGE.CORE.EMAIL.LINE1') | translate }}<br>
          <span class="email-container">
            {{ 'ERROR_PAGE.CORE.EMAIL.LINE2' | translate }}
            <mat-icon class="copy-icon nxt-primary-color" (click)="copyEmail()"
                      [attr.aria-label]="'COMMON.AXA.ICON.COPY' | translate "
                      [attr.aria-hidden]="null">content_copy</mat-icon>
          </span>
        </ng-container>
      </p>
    </div>

    <mat-expansion-panel *ngIf="!!techInfo" class="error-page__tech-info flex-fill" expanded="true"
                         [hideToggle]="hideToggle">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="nxt-blue-marker">{{ 'ERROR_PAGE.CORE.TECH.TITLE'| translate }} </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="container">
        <div class="row mb-2 align-items-center" *ngIf="techInfo.browserUrl">
          <div class="col-3 label">Browser URL:</div>
          <div class="col-9">{{ techInfo.browserUrl }}</div>
        </div>
        <div class="row mb-2 align-items-center" *ngIf="techInfo.apiUrl">
          <div class="col-3 label">API URL:</div>
          <div class="col-9">{{ techInfo.apiUrl }}</div>
        </div>
        <div class="row mb-2 align-items-center" *ngIf="techInfo.RequestTimestamp">
          <div class="col-3 label">Request Timestamp:</div>
          <div class="col-9">{{ techInfo.RequestTimestamp }}</div>
        </div>
        <div class="row mb-2 align-items-center" *ngIf="techInfo.apiStatusCode">
          <div class="col-3 label">API HTTP Status Code:</div>
          <div class="col-9">{{ techInfo.apiStatusCode }}</div>
        </div>
        <div class="row mb-2 align-items-center" *ngIf="techInfo.apiResponseBodyJson">
          <div class="col-12 label">API Response Body:</div>
          <div class="col json-viewer nxt-accent-bg__100 ms-2">{{ techInfo.apiResponseBodyJson | json }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="error-page__image">
    <ng-content select="nxt-error-page__image" />
  </div>
</div>
