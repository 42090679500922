import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DynamicPageTitleStrategyService extends TitleStrategy {
  private readonly titleService = inject(Title);

  override updateTitle(routerStateSnapshot: RouterStateSnapshot) {
    const titleFromRouter = this.buildTitle(routerStateSnapshot);
    const defaultTitle = 'NExt';

    titleFromRouter === undefined || titleFromRouter === '' ?
      this.titleService.setTitle(`${defaultTitle}`)
    : this.titleService.setTitle(`${defaultTitle} | ${titleFromRouter}`);
  }
}
