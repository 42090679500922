import { ChangeDetectionStrategy, Component, computed, HostListener, inject } from '@angular/core';
import { RoutesConstants } from '@core/constants/routes.constants';
import { AuthStateSelectors } from '../../../../auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { Authentication } from '../../../../auth/store/auth.actions';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RouterService } from '@core/services/router.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@core/services/auth.service';
import { FeatureToggleSelectors } from '@core/store/feature-toggles/feature-toggles.selectors';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { environment } from 'src/build-environments/environment';

@Component({
  selector: 'nxt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('changeHeight', [
      state('original', style({ height: '75px' })),
      state('scroll', style({ height: '49px' })),
      transition('original => scroll', [animate('150ms')]),
      transition('scroll => original', [animate('150ms')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    MatToolbar,
    MatButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    LanguageSelectorComponent,
    ProfileMenuComponent,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
  ],
})
export class HeaderComponent {
  currentState = 'original';
  userProfile$ = this.store.select(AuthStateSelectors.userProfile);
  userType = toSignal(this.store.select(AuthStateSelectors.userType));
  hasSelectedCompany$ = this.store.select(AuthStateSelectors.hasSelectedCompany);
  featureToggles = toSignal(this.store.select(FeatureToggleSelectors.featureToggles));
  isBusinessTopicsButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|BUSINESS_TOPICS_BUTTON') ?? false);
  isCompaniesButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|COMPANIES_BUTTON') ?? false);
  isCompanyIdentifiersButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|COMPANY_IDENTIFIERS_BUTTON') ?? false);
  areImportsButtonsAvailable = computed(() => this.featureToggles()?.get('HEADER|IMPORTS_BUTTONS') ?? false);
  isNextOperationsSettingsButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|NEXT_OPERATIONS_BUTTON') ?? false);
  isProvisionsButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|PROVISIONS_BUTTON') ?? false);
  isSubmissionsButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|SUBMISSIONS_BUTTON') ?? false);
  isDialogButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|DIALOG_BUTTON') ?? false);
  isWorkAreasButtonAvailable = computed(() => this.featureToggles()?.get('HEADER|WORK_AREAS_BUTTON') ?? false);
  isBusinessTopicDialogConfigsButtonAvailable = computed(
    () => this.featureToggles()?.get('HEADER|BUSINESS_TOPICS_DIALOG_BUTTON') ?? false
  );

  isMenuEmpty = computed(
    () => false
    /* TODO/FIX: change condition (bug)
      !this.isBusinessTopicsButtonAvailable() ||
      !this.isCompaniesButtonAvailable() ||
      !this.isCompanyIdentifiersButtonAvailable() ||
      !this.areImportsButtonsAvailable() ||
      !this.isNextOperationsSettingsButtonAvailable() ||
      !this.isProvisionsButtonAvailable() ||
      !this.isSubmissionsButtonAvailable() ||
      !this.isWorkAreasButtonAvailable()
      */
  );

  routeConstants = RoutesConstants;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition =
      window.scrollY || (document.documentElement || document.body.parentNode || document.body).scrollTop || 0;

    if (scrollPosition > 0 && this.currentState === 'original') {
      this.currentState = 'scroll';
    } else if (scrollPosition === 0 && this.currentState === 'scroll') {
      this.currentState = 'original';
    }
  }

  showLogo = computed(
    () =>
      ![RoutesConstants.homepageRelative, RoutesConstants.companySelectionRelative, RoutesConstants.loggedOutRelative].includes(
        this.routerSrv.currentRoute()
      )
  );

  showHeaderItems = computed(
    () =>
      ![
        RoutesConstants.companyRegistrationRelative,
        RoutesConstants.companySelectionRelative,
        RoutesConstants.loggedOutRelative,
        RoutesConstants.adfsUnauthorizedRelative,
      ].includes(this.routerSrv.currentRoute())
  );

  userHasBusinessTopics = toSignal(this.store.select(AuthStateSelectors.hasAssignedBusinessTopics));
  showMenu = computed(() => this.userHasBusinessTopics() && !this.isMenuEmpty());

  isAuthenticated = toSignal(inject(AuthService).isAuthenticated$);

  showLogout = computed(
    () =>
      this.isAuthenticated() &&
      ![RoutesConstants.loggedOutRelative, RoutesConstants.adfsUnauthorizedRelative].includes(this.routerSrv.currentRoute())
  );

  isProd = environment.production === 'true';

  constructor(
    private store: Store,
    private routerSrv: RouterService
  ) {}

  logout() {
    this.store.dispatch(new Authentication.Logout());
  }
}
