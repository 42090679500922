import { effect, inject, Injectable, Injector } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';

@Injectable({ providedIn: 'root' })
export class OverviewPaginatorIntl extends MatPaginatorIntl {
  private readonly translateService = inject(TranslateService);
  private languageService = inject(LanguageService);

  constructor(private injector: Injector) {
    super();
    effect(() => {
      this.languageService.currentLanguage();
      this.itemsPerPageLabel = this.translateService.instant('COMMON.PAGINATOR.ITEMS_PER_PAGE');
      this.firstPageLabel = this.translateService.instant('COMMON.PAGINATOR.FIRST_PAGE');
      this.previousPageLabel = this.translateService.instant('COMMON.PAGINATOR.PREVIOUS_PAGE');
      this.nextPageLabel = this.translateService.instant('COMMON.PAGINATOR.NEXT_PAGE');
      this.lastPageLabel = this.translateService.instant('COMMON.PAGINATOR.LAST_PAGE');
      this.changes.next();
    });
  }

  override getRangeLabel: (pageIndex: number, pageSize: number, length: number) => string = (pageIndex, pageSize, length) => {
    const currentPage = pageIndex + 1;
    const totalPages = length ? Math.ceil(length / pageSize) : 1;

    return this.translateService.instant('COMMON.PAGINATOR.OVERVIEW_PAGINATOR_RANGE_LABEL', {
      currentPage: currentPage,
      totalPages: totalPages,
    });
  };
}
