import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from '../build-environments/environment';

export class NExtMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (environment.production === 'true') {
      return;
    }

    console.log(params.key);
    return `${params.key.split('.').pop()} [Missing]`;
  }
}
