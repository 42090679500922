import { ApiErrorBypassRules } from '@core/types';
import { ApiConstants } from '@core/constants/api.constants';
import { RoutesConstants } from '@core/constants/routes.constants';

export const API_ERROR_BYPASS_RULES: ApiErrorBypassRules = {
  COMPANY_IDENTIFIERS__COMPANY_IDENTIFIERS_API__404_20021: {
    route: RoutesConstants.companyIdentifiersRelative,
    api: ApiConstants.COMPANY_IDENTIFIER_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },

  IMPORT_DECISION__IMPORT_DECISION_API__404_20021: {
    route: RoutesConstants.companyMasterDataImportDecisionsRelative,
    api: ApiConstants.COMPANY_IMPORT_DECISION_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },

  IMPORT_DELIVERY__IMPORT_DELIVERY_API__404_20021: {
    route: RoutesConstants.companyMasterDataImportDeliveriesRelative,
    api: ApiConstants.COMPANY_IMPORT_DELIVERY_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },

  IMPORT_RECOMMENDATION_EDIT__COMPANY_API__404_20021: {
    route: RoutesConstants.companyMasterDataImportRecommendationsEditRelative,
    api: ApiConstants.COMPANY_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  IMPORT_RECOMMENDATION__IMPORT_RECOMMENDATION_API__404_20021: {
    route: RoutesConstants.companyMasterDataImportRecommendationsRelative,
    api: ApiConstants.COMPANY_IMPORT_RECOMMENDATION_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },

  PROVISION__PROVISION_API__404_20021: {
    route: RoutesConstants.provisionsRelative,
    api: ApiConstants.PROVISION_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },

  REGISTRATION__COMPANY_TEN_API__404_20021: {
    route: RoutesConstants.companyRegistrationRelative,
    api: ApiConstants.COMPANY_TEN_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  REGISTRATION__COMPANY_TEN_API__409_31613: {
    route: RoutesConstants.companyRegistrationRelative,
    api: ApiConstants.COMPANY_TEN_API_PATH,
    status_code: 409,
    problem_code: '31613',
  },
  REGISTRATION__WORK_AREA_API__404_20021: {
    route: RoutesConstants.companyRegistrationRelative,
    api: ApiConstants.WORK_AREA_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  REGISTRATION__WORK_AREA_UNFILTERED_API__404_20021: {
    route: RoutesConstants.companyRegistrationRelative,
    api: ApiConstants.WORK_AREA_UNFILTERED_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },

  SUBMISSION__BUSINESS_TOPIC_API__404_20021: {
    route: RoutesConstants.submissionsStatusesDetailsRelative,
    api: ApiConstants.BUSINESS_TOPIC_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  SUBMISSION__USER_API__404_20021: {
    route: RoutesConstants.submissionsStatusesDetailsRelative,
    api: ApiConstants.USER_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  SUBMISSION__STATUS_AGGREGATED_API__404_20021: {
    route: RoutesConstants.submissionsStatusesRelative,
    api: ApiConstants.STATUS_AGGREGATED_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  SUBMISSION__SUBMISSION_API__404_20021: {
    route: RoutesConstants.submissionsStatusesRelative,
    api: ApiConstants.SUBMISSION_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  DIALOG__DIALOG_API__404_20021: {
    route: RoutesConstants.dialogRelative,
    api: ApiConstants.DIALOG_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  DIALOG__DIALOG_MESSAGE_API__400_20012: {
    route: RoutesConstants.dialogRelative,
    api: ApiConstants.DIALOG_MESSAGE_API_PATH,
    status_code: 400,
    problem_code: '20012',
  },
  DIALOG__SUBMISSION_API__404_20021: {
    route: RoutesConstants.dialogRelative,
    api: ApiConstants.SUBMISSION_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  DIALOG__PROVISION_API__404_20021: {
    route: RoutesConstants.dialogRelative,
    api: ApiConstants.PROVISION_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
  DIALOG__USER_API__404_20021: {
    route: RoutesConstants.dialogRelative,
    api: ApiConstants.USER_API_PATH,
    status_code: 404,
    problem_code: '20021',
  },
};
