import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Authentication } from '../../../auth/store/auth.actions';
import { SseService } from '@shared/api/server-sent-events/sse.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { environment } from 'src/build-environments/environment';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'nxt-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, TranslateModule, MatIcon],
})
export class LoggedOutComponent {
  isProd = environment.production === 'true';

  constructor(
    private store: Store,
    private sseService: SseService
  ) {
    this.sseService.closeConnection();
  }

  login() {
    this.store.dispatch(new Authentication.Login());
  }
}
