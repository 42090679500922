import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { ChooseFilesButtonComponent } from '@shared/components/choose-files-button/choose-files-button.component';
import { DeleteDialogComponent } from '@shared/components/delete-dialog/delete-dialog.component';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { FileItemComponent } from '@shared/components/file-item/file-item.component';
import { FilterTextComponent } from '@shared/components/filter-text/filter-text.component';
import { OverviewFilterbarComponent } from '@shared/components/filterbar/filterbar.component';
import { GoBackComponent } from '@shared/components/go-back/go-back.component';
import { HistoryItemComponent } from '@shared/components/history-list/history-item/history-item.component';
import { InfoPanelComponent } from '@shared/components/info-panel/info-panel.component';
import { StatusIconComponent } from '@shared/components/status-icon/status-icon.component';
import { StatusLegendComponent } from '@shared/components/status-legend/status-legend.component';
import { WarnPanelComponent } from '@shared/components/warn-panel/warn-panel.component';
import { WorkAreaMiniBoxComponent } from '@shared/components/work-area-mini-box/work-area-mini-box.component';
import { DragAndDropDirective } from '@shared/directives/drag-and-drop.directive';
import { ButtonBarComponent } from './components/button-bar/button-bar.component';
import { CreateButtonComponent } from './components/create-button/create-button.component';
import { DialogLegendComponent } from './components/dialog-legend/dialog-legend.component';
import { DialogStatusIconComponent } from './components/dialog-status-icon/dialog-status-icon.component';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { HistoryListComponent } from './components/history-list/history-list.component';
import { MaterialUiModule } from './material-ui.module';
import { PipesModule } from './pipes/pipes.module';

const declarations = [
  WorkAreaMiniBoxComponent,
  BreadcrumbsComponent,
  ButtonBarComponent,
  DeleteDialogComponent,
  OverviewFilterbarComponent,
  StatusIconComponent,
  GoBackComponent,
  ChooseFilesButtonComponent,
  FileItemComponent,
  InfoPanelComponent,
  FilterTextComponent,
  WarnPanelComponent,
  DragAndDropDirective,
  DialogComponent,
  StatusLegendComponent,
  HistoryItemComponent,
  CreateButtonComponent,
  ExportButtonComponent,
  HistoryListComponent,
  DialogStatusIconComponent,
  DialogLegendComponent,
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MaterialUiModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    PipesModule,
    RouterModule,
    ...declarations,
  ],
  exports: [...declarations, ReactiveFormsModule, MaterialUiModule, PipesModule],
})
export class SharedUIModule {}
