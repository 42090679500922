<header role="banner" aria-label="Header">
  <a class="skip-content-link" href="#skip-link-target" (click)="focusMainContent($event)">{{'COMMON.AXA.LINK.SKIP-CONTENT' | translate}}</a>
  <nxt-header/>
</header>

<main class="ng-next-body"
      [class.image-layout]="[routeConstants.homepageRelative, routeConstants.loggedOutRelative].includes((currentRelativeRouteUrl$ | async) || '')">
  <div class="ng-next-main-container">
    <a href="#" class="skip-link-target" id="skip-link-target">{{'COMMON.AXA.LINK.SKIP-CONTENT-TARGET' | translate}}</a>
    <router-outlet/>
  </div>
</main>

<footer role="contentinfo" aria-label="Footer"><nxt-footer/></footer>
