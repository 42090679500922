import { booleanAttribute, ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { ErrorPageTechInfo } from '@core/types';
import { DatePipe, JsonPipe, NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipesModule } from '@shared/pipes/pipes.module';
import { SharedUIModule } from '@shared/shared-ui.module';
import { Router } from '@angular/router';
import { CoreHelpers } from '@core/helpers/core.helpers';
import { SUPPORT_EMAIL } from '@core/constants/global.constants';
import moment from 'moment';
import { Store } from '@ngxs/store';
import { Notification } from '@core/store/notifications.actions';
import SuccessMessage = Notification.SuccessMessage;

@Component({
  selector: 'nxt-error-page',
  standalone: true,
  imports: [TranslateModule, MatListModule, NgIf, MatExpansionModule, DatePipe, PipesModule, SharedUIModule, JsonPipe],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  store = inject(Store);
  @Input({ transform: booleanAttribute }) showSupportMail = true;
  @Input({ transform: booleanAttribute }) hideToggle = false;
  @Input() emailLine1TransKey?: string;

  techInfo: ErrorPageTechInfo = inject(Router).getCurrentNavigation()?.extras?.state?.['techInfo'] || {
    RequestTimestamp: moment().format('YYYY-MM-DD HH:mm:ss').toString(),
  };

  copyEmail() {
    CoreHelpers.copyText(SUPPORT_EMAIL);
    this.store.dispatch(new SuccessMessage({ messageKey: 'ERROR_PAGE.CORE.EMAIL.COPY_SUCCESS' }));
  }
}
