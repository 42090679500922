<div class="ng-next-footer">
  <div class="ng-next-footer-container my-4">
    <a
      class="label me-5"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.DEUTSCHE_BUNDESBANK' | translate">{{'COMMON.NEXT_FOOTER.DEUTSCHE_BUNDESBANK' | translate}}</a>
    <a
      class="label mx-5"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.IMPRINTED' | translate">{{'COMMON.NEXT_FOOTER.IMPRINTED' | translate}}</a>
    <a
      class="label mx-5"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.FREEDOM_INFORMATION_LAW' | translate">{{'COMMON.NEXT_FOOTER.FREEDOM_INFORMATION_LAW' | translate }}</a>
    <a
      class="label mx-5"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.ACCESSIBILITY' | translate">{{'COMMON.NEXT_FOOTER.ACCESSIBILITY' | translate }}</a>
    <a
      class="label ms-5 me-3"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.PRIVACY' | translate">{{'COMMON.NEXT_FOOTER.PRIVACY' | translate }}</a>
      |
    <a
      class="label mx-3"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.AGB' | translate">{{'COMMON.NEXT_FOOTER.AGB' | translate }}</a>
      |
      <a
      class="label ms-3"
      target="_blank"
      [attr.href]="'COMMON.NEXT_FOOTER.LINK.TERMS_OF_USE' | translate">{{'COMMON.NEXT_FOOTER.TERMS_OF_USE' | translate }}</a>

  </div>
</div>
