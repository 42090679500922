interface findFirstParamsValueInChildrenObject {
  params?: { [key: string]: any };
  [key: string]: any;
}

export class ArrayHelpers {
  // REVIEW with Emre: Make this function generic but typesafe 😉
  public static sortArray<T>(arr: Array<T>, sortBy?: keyof T) {
    if (typeof arr[0] === 'string') {
      return arr.sort();
    } else if (typeof arr[0] === 'object') {
      return arr.sort((a, b) => {
        if (a && b && sortBy && typeof a === 'object' && typeof b === 'object') {
          if (a[sortBy] < b[sortBy]) {
            return -1;
          } else if (a[sortBy] > b[sortBy]) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });
    } else {
      return arr;
    }
  }

  public static findFirstParamsValueInChildren(obj: findFirstParamsValueInChildrenObject | null | undefined): any {
    if (!obj) {
      return;
    }
    if (obj && typeof obj === 'object') {
      if (obj.hasOwnProperty('params')) {
        for (const key in obj.params) {
          if (obj.params.hasOwnProperty(key)) {
            return obj.params[key];
          }
        }
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] && typeof obj[key] === 'object') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const result: string = this.findFirstParamsValueInChildren(obj[key]);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
    return;
  }
}
