<nxt-error-page>
  <nxt-error-page__title>{{ 'ERROR_PAGE.NOT_FOUND.TITLE'| translate }}</nxt-error-page__title>
  <nxt-error-page__description_title>{{ 'ERROR_PAGE.NOT_FOUND.SUBTITLE'| translate }}</nxt-error-page__description_title>
  <nxt-error-page__description_content>
    {{ 'ERROR_PAGE.NOT_FOUND.DESC.LINE1'| translate }}<br>
    {{ 'ERROR_PAGE.NOT_FOUND.DESC.LINE2'| translate }}<br>
  </nxt-error-page__description_content>
  <nxt-error-page__image>
    <img src="img/error-page__404.svg"
         class="not-found__image"
         [attr.alt]="'ERROR_PAGE.404.IMAGE.PLACEHOLDER' | translate"
         height="533"
         width="724">
  </nxt-error-page__image>
</nxt-error-page>

