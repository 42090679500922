import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SimpleSnackbarComponent } from './components/snackbar/simple-snackbar/simple-snackbar.component';
import { DetailedSnackbarComponent } from './components/snackbar/detailed-snackbar/detailed-snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxsModule } from '@ngxs/store';
import { NotificationsState } from '@core/store/notifications.state';
import { ProfileMenuComponent } from '@core/components/layout/header/profile-menu/profile-menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { FooterComponent } from '@core/components/layout/footer/footer.component';
import { HeaderComponent } from '@core/components/layout/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PipesModule } from '@shared/pipes/pipes.module';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';
import { LanguageSelectorComponent } from '@core/components/layout/header/language-selector/language-selector.component';
import { TranslateModule } from '@ngx-translate/core';

const declarations = [
  SimpleSnackbarComponent,
  DetailedSnackbarComponent,
  ProfileMenuComponent,
  FooterComponent,
  LoggedOutComponent,
  LanguageSelectorComponent,
];
const matModules = [
  MatButtonModule,
  MatIconModule,
  MatSnackBarModule,
  MatDividerModule,
  MatMenuModule,
  MatToolbarModule,
  NgOptimizedImage,
  MatDialogModule,
];
@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ...matModules,
    TranslateModule.forChild(),
    NgxsModule.forFeature([NotificationsState]),
    PipesModule,
    ...declarations,
    HeaderComponent,
  ],
  exports: [...declarations, HeaderComponent],
})
export class CoreModule {}
