import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/build-environments/environment';

export const AUTH_CONFIG: AuthConfig = {
  issuer: `${environment.authIssuer}`,
  clientId: `${environment.iamClientId}`,
  redirectUri: window.location.origin,
  responseType: 'code',
  scope: 'openid email profile', // Ask offline_access to support refresh token refreshes
  timeoutFactor: 0.25, // For faster testing
  useSilentRefresh: true,
  silentRefreshTimeout: 10000,
  clearHashAfterLogin: true, //REVIEW: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by ID Server's URI encoding
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  strictDiscoveryDocumentValidation: false, // Needs to be disabled due to non standard 'terminateSession' endpoint (instead of default 'endSession')
  postLogoutRedirectUri: `${window.location.origin}/logged-out`, // Navigate to logged-out page after successful logout (needs to be allowed explicitly in OW tenant config)
};
