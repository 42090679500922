<div class="container">
    <div class="row image-row">
      <div class="col d-flex justify-content-center align-items-center">
        @if (isProd) {
          <mat-icon
            class="next-logo"
            [attr.alt]="'COMMON.AXA.ICON.HEADER.LOGO' | translate"
            aria-hidden="false"
            svgIcon="next_logo" />
        } @else {
          <img
            class="next-logo"
            src="img/Next_Logo_test.png"
            [attr.alt]="'COMMON.AXA.ICON.HEADER.LOGO' | translate"
            height="16"
            width="16" aria-hidden="false" />
        }
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <h2 class="nxt-blue-marker">
          {{ 'HOMEPAGE.HEADER'| translate }}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <p class="company">
          <span class="subtitle">{{ 'HOMEPAGE.COMPANY_TITLE'| translate }}</span>
          <span class="selected-company">{{ selectedCompany$ | async }}</span>
        </p>
      </div>
    </div>
    <ng-container *ngIf="!hasAssignedBusinessTopics()">
    <div class="col d-flex justify-content-center align-items-center">
      <p data-test-id="no-business-topics-info" class="no-business-topics">
        <span class="subtitle">
            {{'HOMEPAGE.NO_BUSINESS_TOPICS.TITLE' | translate }}
        </span>
        <span>{{'HOMEPAGE.NO_BUSINESS_TOPICS.SITUATION'| translate }}</span>
        <br><br>
        <span>{{'HOMEPAGE.NO_BUSINESS_TOPICS.EXPLANATION'| translate }}</span>
      </p>
    </div>
    </ng-container>
</div>
