import { APP_INITIALIZER, enableProdMode, importProvidersFrom, Provider } from '@angular/core';

import { DatePipe, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { NXT_DATE_FORMATS } from '@core/helpers/date.helpers';
import { NextAPIErrorInterceptor } from '@core/interceptors/next-api-error.interceptor';
import { DynamicPageTitleStrategyService } from '@core/services/dynamic-page-title.service';
import { OverviewPaginatorIntl } from '@core/services/next-paginator-intl.service';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AuthModule } from './app/auth/auth.module';
import { NExtMissingTranslationHandler } from './app/missing-translation-handler';
import { environment } from './build-environments/environment';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { DialogModule } from './app/dialog/dialog.module';

function createTranslateHttpLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'i18n/', `.json?v=${Date.now()}`);
}

const providers: Array<Provider> = [
  { provide: APP_INITIALIZER, useFactory: translationAppInitializerFactory, deps: [TranslateService], multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NextAPIErrorInterceptor, multi: true },
  { provide: MatPaginatorIntl, useClass: OverviewPaginatorIntl },
  { provide: TitleStrategy, useClass: DynamicPageTitleStrategyService },
  { provide: DateAdapter, useClass: MomentDateAdapter },
  { provide: MAT_DATE_FORMATS, useValue: NXT_DATE_FORMATS },
  { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
  DatePipe,
];

export const translateModuleConfig = {
  useDefaultLang: environment.production === 'true',
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: NExtMissingTranslationHandler },
};

function translationAppInitializerFactory(translateService: TranslateService) {
  return () => lastValueFrom(translateService.use('de'));
}

if (environment.production === 'true') {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    ...providers,
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      NgxsModule.forRoot([], {
        developmentMode: !(environment.production === 'true'),
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
      }),
      NgxsRouterPluginModule.forRoot(),
      TranslateModule.forRoot({
        loader: { provide: TranslateLoader, useFactory: createTranslateHttpLoader, deps: [HttpClient] },
        ...translateModuleConfig,
      }),
      // IMPORTANT: This should always be the last imported module among all NgXs plugins
      NgxsReduxDevtoolsPluginModule.forRoot({
        name: 'NExtUI Store',
        disabled: environment.production === 'true', // We do not want the Redux DevTools to be usable in a production build
      }),
      AuthModule.forRoot(),
      NgOptimizedImage,
      CoreModule,
      MatIconModule,
      DialogModule
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
