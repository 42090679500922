import { Injectable } from '@angular/core';
import { Action as OnAction, State, StateContext, StateToken } from '@ngxs/store';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackType } from '@core/types';
import { Notification } from '@core/store/notifications.actions';
import SuccessMessage = Notification.SuccessMessage;
import FailedMessage = Notification.FailedMessage;

export type NotificationsStateModel = {
  type?: SnackType;
  message?: string;
};
export const NOTIFICATIONS_STATE = new StateToken<NotificationsStateModel>('notifications');
@Injectable()
@State({
  name: NOTIFICATIONS_STATE,
  defaults: {
    type: undefined,
    message: undefined,
  },
})
export class NotificationsState {
  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  @OnAction(SuccessMessage)
  successMessage(ctx: StateContext<NotificationsStateModel>, { payload }: SuccessMessage) {
    this.snackbar.openSnackBar('SUCCESS', payload.messageKey, payload.messageParams);

    return ctx.patchState({ type: 'SUCCESS', message: payload.messageKey });
  }

  @OnAction(FailedMessage)
  failedMessage(ctx: StateContext<NotificationsStateModel>, { payload }: FailedMessage) {
    this.snackbar.openSnackBar('ERROR', payload.messageKey, payload.messageParams);

    return ctx.patchState({ type: 'ERROR', message: payload.messageKey });
  }
}
