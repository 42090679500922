import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { API_ERROR_BYPASS_RULES } from '@core/constants/error.constants';
import { ArrayHelpers } from '@core/helpers/array.helpers';
import { CoreHelpers } from '@core/helpers/core.helpers';
import { SnackbarService } from '@core/services/snackbar.service';
import { RouterSelectors } from '@core/store/router.selectors';
import { BypassInfoCheck } from '@core/types';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ErrorResponsePayload } from '@shared/api/base/error-response.model';
import { PRE_FLIGHT_KEY } from '../constants/global.constants';
import { RoutesConstants } from '../constants/routes.constants';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  snackbarService = inject(SnackbarService);
  authService = inject(AuthService);
  store = inject(Store);
  paramsValue = '';
  urlWithoutParamValue = '';
  routerStateUrl = toSignal(this.store.select(RouterSelectors.url));
  routerStateRoot = toSignal(this.store.select(RouterSelectors.root));

  handleError(request: HttpRequest<unknown>, response: HttpErrorResponse) {
    const url = this.routerStateUrl();
    const root = this.routerStateRoot();
    if (!!url && !!root) {
      this.paramsValue = ArrayHelpers.findFirstParamsValueInChildren(root) ?? '';
      this.urlWithoutParamValue = !!this.paramsValue ? url.replace(`/${this.paramsValue}`, '') : url;
    }

    const bypassInfoCheck: BypassInfoCheck = {
      route: this.urlWithoutParamValue,
      api: request.url,
      status_code: +response.status,
      problem_code: (response.error as ErrorResponsePayload)?.problem?.code ?? null,
    };

    const techInfoNavigationExtras = CoreHelpers.buildTechInfo(url, response);

    if (!request.headers.has(PRE_FLIGHT_KEY) && +response.status === 400) {
      const problems = (response.error as ErrorResponsePayload)?.problems;
      if (problems) {
        const problemCodes = Object.values(problems)
          .flatMap((propertyProblems) => {
            return propertyProblems?.map((problem) => problem.code);
          })
          .filter((problemCode) => problemCode !== undefined);

        /**
         * 20012: maxLength validation failed
         */
        if (problemCodes.includes('20012')) {
          if (this.isBypassError({ ...bypassInfoCheck, problem_code: '20012' })) {
            return;
          }
        }
      }

      this.snackbarService.openSnackBar('ERROR', 'COMMON.ERROR_SNACKBAR.BAD_REQUEST');
    }

    if (+response.status === 401) {
      if (
        [
          RoutesConstants.loggedOutRelative,
          RoutesConstants.adfsUnauthorizedRelative,
          RoutesConstants.internalServerErrorRelative,
        ].includes(this.urlWithoutParamValue)
      ) {
        return;
      }

      this.snackbarService.openSnackBar('ERROR', 'COMMON.ERROR_SNACKBAR.UNAUTHORIZED');

      // DISCUSS: generic refresh leads to the problem, that a failed PATCH on User on logout will refresh the tokens
      this.authService.refreshTokens().then();
    }

    if (+response.status === 404) {
      /**
       * 20020: no RESOURCE found for the requested URI
       * 20021: no results found, please try another filter
       * 20022: there is no RESOURCE found for the requested BUSINESSKEY
       */
      if (['20020', '20021', '20022'].includes((response.error as ErrorResponsePayload)?.problem?.code ?? '')) {
        return;
      }

      this.store.dispatch(new Navigate([RoutesConstants.notFoundRelative], undefined, techInfoNavigationExtras));
    }

    if (+response.status === 409 && ['GET', 'PUT', 'POST', 'PATCH', 'DELETE'].includes(request.method)) {
      if ((response.error as ErrorResponsePayload)?.problem?.code === '31613') {
        if (this.isBypassError(bypassInfoCheck)) {
          return;
        }
      }
      this.snackbarService.openSnackBar('CONFLICT', response);
    }

    if (+response.status === 500) {
      this.store.dispatch(new Navigate([RoutesConstants.internalServerErrorRelative], undefined, techInfoNavigationExtras));
    }

    if (+response.status === 403) {
      this.store.dispatch(new Navigate([RoutesConstants.forbiddenRelative], undefined, techInfoNavigationExtras));
    }
  }

  isBypassError(bypassInfoCheck: BypassInfoCheck): boolean {
    const matchingEntries = Object.values(API_ERROR_BYPASS_RULES).filter((rule) => {
      return (
        rule.route === bypassInfoCheck.route &&
        (bypassInfoCheck.api.endsWith(rule.api) || bypassInfoCheck.api.indexOf(rule.api + '/') > 0) &&
        rule.status_code === bypassInfoCheck.status_code &&
        (rule.problem_code === null || rule.problem_code === bypassInfoCheck.problem_code)
      );
    });

    return matchingEntries.length > 0;
  }
}
