import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthStateSelectors } from '../auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';
import { environment } from 'src/build-environments/environment';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'nxt-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe, TranslateModule, MatIcon],
})
export class HomepageComponent {
  store = inject(Store);
  selectedCompany$ = this.store.select(AuthStateSelectors.selectedCompanyName);
  hasAssignedBusinessTopics = toSignal(this.store.select(AuthStateSelectors.hasAssignedBusinessTopics));
  isProd = environment.production === 'true';
}
