import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { AriaLabelPipe } from './aria-label.pipe';
import { FunctionPipe } from '@shared/pipes/function.pipe';

const declarations = [SafeHtmlPipe, FunctionPipe, AriaLabelPipe];

@NgModule({
  imports: [...declarations],
  exports: declarations,
  providers: [],
})
export class PipesModule {}
